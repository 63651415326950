import { SiteSlug } from '@volvo-cars/market-sites';
import { Retailer } from 'src/types/retailer';
import { DictionaryItemTypes } from '../content-management/DictionaryItemTypes';
import { useFeatureFlags } from './useFeatureFlags';

/**
 * Returns an object array of a retailer's available links. The links that are checked:
 *  - newCarLocator (New Inventory)
 *  - localOffers (Vehicle Offers)
 *  - usedCarLocator (Pre-Owned)
 *  - workshop (Schedule Service)
 */

export type RetailerLink = {
  url: string;
  key: keyof DictionaryItemTypes;
  eventLabel: string;
  defaultLabel: string;
  source: LinkSource;
};

enum LinkSource {
  RDM = 'RDM',
  NON_RDM = 'NON_RDM',
}

type UnfilteredRetailerLink = Omit<RetailerLink, 'url'> & {
  url: string | null | undefined;
};

export const useRetailerLinks = (retailer: Retailer, siteSlug: SiteSlug) => {
  const {
    enableRDMLinks,
    usePurchaseHelp,
    enableQuoteRequestLink,
    enableTestDriveLink,
  } = useFeatureFlags();

  const {
    newCarLocator,
    localOffers,
    usedCarLocator,
    workshop,
    partsOffer,
    testDrive,
    quoteRequest,
    shop,
  } = retailer;

  const unfilteredLinks: UnfilteredRetailerLink[] = [
    {
      url: testDrive,
      key: 'global.retailer.testDrive',
      eventLabel: 'test-drive',
      defaultLabel: 'Book a test drive',
      source: LinkSource.NON_RDM,
    },
    {
      url: quoteRequest,
      key: usePurchaseHelp
        ? 'global.retailer.helpPurchaseRequest'
        : 'global.retailer.quoteRequest',
      eventLabel: 'quote-request',
      defaultLabel: usePurchaseHelp
        ? 'Get help with your purchase'
        : 'Get a quote',
      source: LinkSource.NON_RDM,
    },
    {
      url: newCarLocator,
      key: 'global.retailer.newCarLocator',
      eventLabel: 'new-car-locator',
      defaultLabel: 'New inventory',
      source: LinkSource.RDM,
    },
    {
      url: localOffers,
      key: 'global.retailer.localOffers',
      eventLabel: 'local-offers',
      defaultLabel: 'Vehicle offers',
      source: LinkSource.RDM,
    },
    {
      url: usedCarLocator,
      key: 'global.retailer.usedCarLocator',
      eventLabel: 'used-car-locator',
      defaultLabel: 'Pre-owned',
      source: LinkSource.RDM,
    },
    {
      url: workshop,
      key: 'global.retailer.scheduleService',
      eventLabel: 'schedule-service',
      defaultLabel: 'Schedule service',
      source: LinkSource.RDM,
    },
    {
      url: partsOffer,
      key: 'global.retailer.partsOffer',
      eventLabel: 'parts-offer',
      defaultLabel: 'Parts offer',
      source: LinkSource.RDM,
    },
    {
      url: shop,
      key: 'global.retailer.newCarShop',
      eventLabel: 'new-car-locator',
      defaultLabel: 'View new car stock',
      source: LinkSource.NON_RDM,
    },
  ];

  return unfilteredLinks
    .filter((l): l is RetailerLink => !!l.url)
    .filter((l) => enableRDMLinks || l.source === LinkSource.NON_RDM)
    .filter((l) => enableTestDriveLink || l.eventLabel !== 'test-drive')
    .filter((l) => enableQuoteRequestLink || l.eventLabel !== 'quote-request')
    .filter((l) => siteSlug !== 'uk' || l.eventLabel !== 'schedule-service')
    .filter(
      (l) => siteSlug !== 'uk' || l.key !== 'global.retailer.newCarLocator',
    )
    .filter((l) => siteSlug === 'uk' || l.key !== 'global.retailer.newCarShop');
};
