import React, { createContext, useCallback, useMemo, useState } from 'react';
import { useCurrentMarketSite } from '@vcc-www/market-sites';
import { Retailer } from 'src/types/retailer';
import { useStore } from 'src/hooks';
import { isUseMyLocationAddress } from 'src/utils/isUseMyLocationAddress';
import { getDistance } from 'src/utils/getDistance';
import { formUrl } from 'src/utils/formUrl';
import { logError } from 'src/utils/logError';
import { ErrorMessages } from '../constants/errorMessages';

type RetailerDrivingData = {
  distance: number;
  duration: string;
  isLoading: boolean;
};

type OriginCache = Map<string, RetailerDrivingData>;

const initialCache = new Map<string, OriginCache>();

type DrivingDistanceContentValue = {
  registerRetailers: (retailers: Retailer[] | undefined) => void;
  drivingDistanceCache: typeof initialCache;
  getDrivingData: (retailer?: Retailer) => RetailerDrivingData;
  isUseMyLocationAddress: boolean;
};

export const DrivingDistanceContext =
  createContext<DrivingDistanceContentValue>({
    registerRetailers: () => {},
    drivingDistanceCache: initialCache,
    getDrivingData: () => ({ distance: 0, duration: '', isLoading: false }),
    isUseMyLocationAddress: false,
  });

type DrivingDistanceProviderProps = React.PropsWithChildren<{
  drivingDistanceCache?: typeof initialCache;
}>;
export const DrivingDistanceProvider = ({
  children,
  drivingDistanceCache = initialCache,
}: DrivingDistanceProviderProps): JSX.Element => {
  const { address } = useStore();
  const { languageCode, locale } = useCurrentMarketSite();
  const currentOriginId = useMemo(
    () =>
      (isUseMyLocationAddress(address) &&
        getOriginId(address?.coords ?? { latitude: '', longitude: '' })) ||
      '',
    [address],
  );
  const [cache, setCache] = useState(drivingDistanceCache);
  const registerRetailers = useCallback<
    DrivingDistanceContentValue['registerRetailers']
  >(
    (retailers) => {
      if (!currentOriginId) return;
      const retailersToFetchDrivingDistance = getUncachedRetailersByOrigin(
        retailers,
        currentOriginId,
      );
      if (
        !retailersToFetchDrivingDistance ||
        !retailersToFetchDrivingDistance.length
      )
        return;
      const [latitude, longitude] = currentOriginId.split('|');
      fetchDrivingData(
        retailersToFetchDrivingDistance,
        {
          latitude,
          longitude,
        },
        languageCode,
        locale,
      )
        .then((response) => {
          const data = response.data;
          const fillOriginCache = (originCache: OriginCache) => {
            return retailersToFetchDrivingDistance.reduce(
              (originCache, { latitude, longitude }, index) => {
                const retailerId = getIdFromCoords({
                  latitude: latitude ?? '',
                  longitude: longitude ?? '',
                });
                // null = no retailer data in the response
                originCache.set(retailerId, data[index] || null);
                return originCache;
              },
              originCache,
            );
          };
          const originCache = drivingDistanceCache.get(currentOriginId);
          if (originCache) {
            fillOriginCache(originCache);
          } else {
            const originCache = fillOriginCache(new Map());
            drivingDistanceCache.set(currentOriginId, originCache);
          }
          setCache(new Map(drivingDistanceCache));
        })
        .catch((error) => {
          logError(`${ErrorMessages.DRIVING_DISTANCE_FETCH_ERROR}: ${error}`);
        });
    },
    [currentOriginId, languageCode, locale, drivingDistanceCache],
  );
  const getDrivingData = useCallback(
    (retailer?: Retailer) => {
      if (!retailer || !currentOriginId)
        return { distance: 0, duration: '', isLoading: false };
      const retailerId = getIdFromCoords({
        latitude: retailer.latitude ?? '',
        longitude: retailer.longitude ?? '',
      });
      const retailerDrivingData = cache.get(currentOriginId)?.get(retailerId);
      return {
        isLoading: !!(
          currentOriginId &&
          !retailerDrivingData &&
          retailerDrivingData !== null
        ),
        distance: retailerDrivingData?.distance || 0,
        duration: retailerDrivingData?.duration || '',
      };
    },
    [cache, currentOriginId],
  );
  const value = useMemo(
    () => ({
      registerRetailers,
      drivingDistanceCache: cache,
      isUseMyLocationAddress: !!currentOriginId,
      getDrivingData,
    }),
    [cache, getDrivingData, registerRetailers, currentOriginId],
  );
  return (
    <DrivingDistanceContext.Provider value={value}>
      {children}
    </DrivingDistanceContext.Provider>
  );
};

type UseMyLocationCoords = {
  latitude: number | string;
  longitude: number | string;
};

export function getUncachedRetailersByOrigin(
  retailers: Retailer[] | undefined,
  originId: string,
  cache = initialCache,
) {
  if (!retailers || !originId) return retailers;
  const cachedRetailersByOrigin = cache.get(originId);
  if (!cachedRetailersByOrigin) return retailers;
  return retailers.filter(({ latitude, longitude }) => {
    const retailerId = getIdFromCoords({
      latitude: latitude ?? '',
      longitude: longitude ?? '',
    });
    return (
      !cachedRetailersByOrigin.has(retailerId) ||
      // null signifies that the request succeeded but the response didn't have the retailer driving data
      !cachedRetailersByOrigin.get(retailerId)
    );
  });
}

export function getIdFromCoords({ latitude, longitude }: UseMyLocationCoords) {
  return `${latitude}|${longitude}`;
}

export function getOriginId(
  originCoords: UseMyLocationCoords,
  tolerance = 100,
  cache = initialCache,
) {
  const originLatitude = parseFloat(originCoords.latitude as string),
    originLongitude = parseFloat(originCoords.longitude as string);
  return (
    Array.from(cache.keys()).find((key) => {
      const [latitude, longitude] = key.split('|');
      const distance = getDistance(
        [originLatitude, originLongitude],
        [parseFloat(latitude), parseFloat(longitude)],
        6371 * 10 ** 3,
      );
      // Consider the same origin if haversine distance between two origins is less than tolerance
      return distance <= tolerance;
    }) || getIdFromCoords(originCoords)
  );
}

async function fetchDrivingData(
  retailers: Retailer[] | undefined,
  originCoords: UseMyLocationCoords,
  languageCode: string,
  locale: string,
) {
  if (!retailers || !originCoords.latitude || !originCoords.longitude) return;
  const retailersCoords = retailers
    .map(({ longitude, latitude }) => [latitude, longitude])
    .filter(([lat, lng]) => lat && lng);
  const originsCoords = [
    parseFloat(originCoords.latitude as string),
    parseFloat(originCoords.longitude as string),
  ];
  const params = {
    origins: originsCoords,
    destinations: retailersCoords.map((d) => `${d[0]}|${d[1]}`),
    mode: 'driving',
    language: languageCode,
    locale,
  };
  const url = formUrl('/api/dealers/get-driving-data', params);
  const result = await fetch(url);
  const jsonResponse = await result.json();
  if (result.ok) {
    return jsonResponse;
  } else {
    throw Error(jsonResponse);
  }
}
