const flagGroups: FlagGroup[] = [
  'setting',
  'feature',
  'operations',
  'experiment',
];
type FlagGroup = keyof Flags;

export type RawFeatureFlags = {
  'feature.enableDatelineCrossing'?: boolean;
  'feature.enableExtendedRetailerCard'?: boolean;
  'feature.enableQuoteRequestLink'?: boolean;
  'feature.enableRDMLinks'?: boolean;
  'feature.enableTestDriveLink'?: boolean;
  'feature.mapReboundMaxRadius'?: -1;
  'feature.marketIsDisabled'?: boolean;
  'feature.usePurchaseHelp'?: boolean;
  'feature.useTerritoryDealer'?: boolean;
  'feature.useZipCodeSearch'?: boolean;
  'setting.coordinatesMapLink'?: boolean;
};

export type Flags = {
  setting?: {
    coordinatesMapLink?: boolean;
  };
  feature?: {
    enableTestDriveLink?: boolean | null;
    enableQuoteRequestLink?: boolean | null;
    enableRDMLinks?: boolean | null;
    enableExtendedRetailerCard?: boolean | null;
    enableDatelineCrossing?: boolean | null;
    mapReboundMaxRadius?: number | null;
    marketIsDisabled?: boolean | null;
    useTerritoryDealer?: boolean | null;
    useZipCodeSearch?: boolean | null;
    usePurchaseHelp?: boolean | null;
  };
  operations?: object;
  experiment?: object;
};

export const parseFeatureFlags = (flags: RawFeatureFlags) => {
  const parsedFlags = Object.entries(flags).reduce((acc, [key, value]) => {
    const [group, flagName] = key?.split('.') as [keyof Flags, string];
    if (flagGroups.includes(group)) {
      if (acc[group]) {
        acc[group] = { ...acc[group], [flagName]: value || false };
      } else {
        acc[group] = { [flagName]: value || false };
      }
    }
    return acc;
  }, {} as Flags);
  return parsedFlags;
};
